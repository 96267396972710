<template>
  <div
    class="tw-faq-accordion"
    :style="accordionStyle"
  >
    <div
      class="tw-faq-accordion__title"
      @click="toggle"
    >
      <div class="tw-faq-accordion__title-content">
        <template v-if="$slots.title">
          <slot name="title" v-bind="props" />
        </template>
        <template v-else>
          {{ title }}
        </template>
      </div>
      <div
        class="transition-all"
        :class="iconClass"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M8.12266 9.00246L12.0027 12.8825L15.8827 9.00246C16.2727 8.61246 16.9027 8.61246 17.2927 9.00246C17.6827 9.39246 17.6827 10.0225 17.2927 10.4125L12.7027 15.0025C12.3127 15.3925 11.6827 15.3925 11.2927 15.0025L6.70266 10.4125C6.31266 10.0225 6.31266 9.39246 6.70266 9.00246C7.09266 8.62246 7.73266 8.61246 8.12266 9.00246Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
    <Transition
      name="tw-faq-accordion"
      @enter="(onTransitionStart as any)"
      @after-enter="(onTransitionEnd as any)"
      @before-leave="(onTransitionStart as any)"
      @after-leave="(onTransitionEnd as any)"
    >
      <div v-show="props.open && hasContent" class="tw-faq-accordion__content-collapse">
        <div class="tw-faq-accordion__content">
          <template v-if="$slots.default">
            <slot />
          </template>
          <template v-else>
            {{ content }}
          </template>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import type { PageOptions, Element as ElementType } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';

import { computed, type PropType, useSlots } from 'vue';

const props = defineProps({
  open: { type: Boolean, default: false },
  title: { type: String, default: '' },
  content: { type: String, default: '' },
  id: { type: Number, default: -1 },
  style: { type: Object as PropType<ElementType<'faq'>['style']>, default: () => ({}) },
  options: { type: Object as PropType<ElementOptions['faq']>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) }
});

const emit = defineEmits(['opened', 'closed']);
const slots = useSlots();

const iconClass = computed(() => [
  'tw-faq-accordion__icon',
  { 'rotate-180': props.open }
]);

const hasContent = computed(() => slots.default || props.content);

const accordionStyle = computed(() => {
  return {
    color: props.style.color || props.pageOptions.colors.text[0],
    borderColor: props.options.border?.color || '',
    borderWidth: `${props.options.border?.width || 0}px`,
    borderRadius: `${props.options.border?.radius !== null ? props.options.border?.radius : props.pageOptions.borderRadius}px`,
    backgroundColor: props.options.bgColor || undefined,
    fontFamily: props.style.fontFamily || props.pageOptions?.fontFamily,
    fontSize: props.style.fontSize
  }
});
const toggle = () => {
  if (!props.open) {
    emit('opened', props.id);
  } else {
    emit('closed');
  }
};
const onTransitionStart = (el: HTMLElement) => {
  el.style.height = el.scrollHeight + 'px';
};
const onTransitionEnd = (el: HTMLElement) => {
  el.style.height = '';
};
</script>

<style lang="postcss">
.tw-faq-accordion {
  @apply px-24 mb-16 overflow-hidden mr-8 border-[1px] border-neutral-200 font-normal hover:border-primary-500 focus:border-primary-500 focus:ring-4 focus:ring-primary-100 focus:ring-offset-0;
  &__title {
    @apply flex cursor-pointer items-center py-12 font-medium;
    &-content {
      @apply flex-1 truncate break-all;
    }
  }
  &__content {
    @apply pt-0 pb-16 break-all;
  }
  &--disabled {
    &.tw-faq-accordion {
      @apply border-neutral-100 bg-neutral-100 hover:border-neutral-100;
      .tw-faq-accordion {
        &__title,
        &__content {
          @apply cursor-not-allowed select-none text-neutral-300;
        }
      }
    }
  }
  &--no-border {
    &.tw-faq-accordion {
      @apply border-none focus:ring-0;
    }
  }
  &:focus:not(.tw-faq-accordion--no-border) {
    .tw-faq-accordion__icon {
      @apply text-primary-500;
    }
  }
  &-enter-active,
  &-leave-active {
    @apply overflow-hidden transition-[height,opacity] duration-300 ease-in-out will-change-[height,opacity];
  }

  &-enter-from,
  &-leave-to {
    @apply !h-0 opacity-0;
  }
}
</style>
